<template>
	<div class="block-inner">
		<figure>
			<figcaption v-if="caption">
				{{ caption }}
			</figcaption>

			<template v-if="audioUrl">
				<audio ref="player" :src="audioUrl" crossorigin="anonymous" />
				<div class="audio-background">
					<div class="progress" :style="`width: ${progress}%;`" />
					<canvas ref="canvas" />
					<button type="button" :class="isPlaying ? 'playing' : ''" aria-label="Play" :disabled="isLoading" @click="play">
						<IconStop v-if="isPlaying" :width="32" :height="32" :strokewidth="2" />
						<IconSpinner v-else-if="isLoading" :width="32" :height="32" />
						<IconPlay v-else :width="32" :height="32" :strokewidth="2" />
					</button>
				</div>
				<div class="meta">
					<div v-if="time" class="time">
						Time: <span class="value">{{ time }}</span>
					</div>
					<div v-if="block.meta.playbackLimit && isQuiz" class="tries">
						Tries: <span class="value">{{ tries }} / {{ block.meta.playbackLimit }}</span>
					</div>
				</div>
			</template>

			<p v-else>
				Audio block: No audio file selected
			</p>
		</figure>

		<MediaTranscript v-if="block.meta.transcript" :text="block.meta.transcript" />
	</div>
</template>

<script>
	import Backend from '../../inc/backend';
	import Bus from '../../inc/bus';
	import {getSettings, updateProgress} from '../../inc/courseUtils';
	import ObjectStore from '../../inc/objectStore';
	import Store from '../../inc/store';
	import IconPlay from '../icons/IconPlay.vue';
	import IconStop from '../icons/IconStop.vue';
	import IconSpinner from '../icons/IconSpinner.vue';
	import MediaTranscript from '../MediaTranscript.vue';

	const state = Object.freeze({
		NOT_LOADED: 0,
		LOADING: 1,
		LOADED: 2,
		PLAYING: 3,
		ERROR: 4
	});

	export default {
		name: 'BlockAudio',
		components: {
			MediaTranscript,
			IconPlay,
			IconStop,
			IconSpinner
		},
		props: {
			block: {
				type: Object,
				required: true
			},
			caption: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				audioUrl: '',
				state: state.NOT_LOADED,
				audioData: [],
				responsePromise: null,
				time: '',
				progress: 0
			};
		},
		computed: {
			progressMeta() {
				return Store.courseGroupProgress.meta || {};
			},
			isPlaying() {
				return this.state === state.PLAYING;
			},
			isLoading() {
				return this.state === state.LOADING;
			},
			tries() {
				let tries = 0;

				if(this.block.meta.playbackLimit) {
					tries = this.block.meta.playbackLimit;

					if(this.progressMeta[this.block.id] && this.progressMeta[this.block.id].playbacks) {
						tries -= this.progressMeta[this.block.id].playbacks;
					}
				}

				return tries;
			},
			isQuiz() {
				const settings = Store.courseGroup ? getSettings(Store.courseGroup) : {};

				if(settings.timeLimit) {
					return settings.timeLimit.value > 0;
				}

				return false;
			}
		},
		created() {
			if(this.block.content.files && this.block.content.files.length) {
				ObjectStore.getFileUrl(this.block.content.files[0]).then(url => {
					this.audioUrl = url;
					this.responsePromise = fetch(url).then(response => response.arrayBuffer());
				});
			}
		},
		beforeUnmount() {
			if(this.state === state.PLAYING) {
				this.stop();
			}
		},
		methods: {
			stop() {
				this.$refs.player.pause();
				this.$refs.player.currentTime = 0;
				this.state = state.LOADED;
			},
			play() {
				if(this.state === state.PLAYING) {
					this.stop();

					return;
				}

				if(this.isQuiz && (this.tries < 1) && this.block.meta.playbackLimit > 0) {
					Bus.emit('error', 'You can\'t listen to this audio clip any more.');

					return;
				}

				const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
				let audio = Promise.resolve();

				if(this.state === state.LOADED) {
					audio = this.updateProgress();
				}
				else if(this.state !== state.ERROR) {
					this.state = state.LOADING;
					audio = this.responsePromise
						.then(arrayBuffer => audioCtx.decodeAudioData(arrayBuffer))
						.then(audioBuffer => this.filterData(audioBuffer))
						.then(filteredData => {
							this.audioData = filteredData;

							audioCtx.createMediaElementSource(this.$refs.player).connect(audioCtx.destination);
							this.time = Math.round(this.$refs.player.duration);

							this.$refs.player.addEventListener('ended', this.stop, false);
							this.$refs.player.addEventListener('timeupdate', this.updatePlayback, false);

							return this.updateProgress();
						})
						.then(() => {
							this.draw();
							this.state = state.LOADED;
						});
				}

				audio
					.catch(() => {
						this.state = state.ERROR;
					})
					.then(() => {
						if(this.state === state.LOADED) {
							this.$refs.player.play();
							this.state = state.PLAYING;
						}
						else {
							Bus.emit('error', 'Unable to play audio clip.');
						}
					});
			},
			updatePlayback() {
				const currentTime = Math.floor(this.$refs.player.currentTime);
				const duration = Math.floor(this.$refs.player.duration);
				const remaining = duration - currentTime;

				this.progress = Math.round(this.$refs.player.currentTime / duration * 100);

				if((duration - currentTime) > 59) {
					this.time = `${Math.floor(remaining / 60)}, min ${remaining % 60} sec`;
				}
				else {
					this.time = `${remaining % 60} sec`;
				}
			},
			updateProgress() {
				if(!this.block.meta.playbackLimit || !this.isQuiz) {
					return Promise.resolve();
				}

				const meta = this.progressMeta;

				if(!meta[this.block.id]) {
					meta[this.block.id] = {
						playbacks: 1
					};
				}
				else if(typeof meta[this.block.id].playbacks === 'undefined') {
					meta[this.block.id].playbacks = 1;
				}
				else {
					meta[this.block.id].playbacks += 1;
				}

				meta[this.block.id].playbacks = Math.min(meta[this.block.id].playbacks, this.block.meta.playbackLimit);

				return Backend.put(`sessions/${Store.session.id}/groups/${Store.courseGroup.id}/meta`, meta)
					.then(() => updateProgress(Store.courseGroup.id));
			},
			draw() {
				const {canvas} = this.$refs;
				const dpr = window.devicePixelRatio || 1;
				const padding = 20;

				canvas.width = canvas.offsetWidth * dpr;
				canvas.height = (canvas.offsetHeight + (padding * 2)) * dpr;

				const ctx = canvas.getContext('2d');

				ctx.scale(dpr, dpr);
				ctx.translate(0, (canvas.offsetHeight / 2) + padding);

				const width = canvas.offsetWidth / this.audioData.length;

				for(let i = 0; i < this.audioData.length; i++) {
					const x = width * i;
					let y = (this.audioData[i] * canvas.offsetHeight) - padding;

					if(y < 0) {
						y = 0;
					}
					else if(y > canvas.offsetHeight / 2) {
						y = canvas.offsetHeight / 2;
					}
					this.drawLineSegment(ctx, x, y, width, (i + 1) % 2);
				}
			},
			drawLineSegment(ctx, x, y, width, isEven) {
				ctx.lineWidth = 1;
				ctx.strokeStyle = '#fff';
				ctx.beginPath();
				ctx.moveTo(x, 0);
				ctx.lineTo(x, (isEven ? y : -y));
				ctx.arc(x + (width / 2), (isEven ? y : -y), width / 2, Math.PI, 0, isEven);
				ctx.lineTo(x + width, 0);
				ctx.stroke();
			},
			filterData(audioBuffer) {
				const rawData = audioBuffer.getChannelData(0);
				const samples = 70;
				const blockSize = Math.floor(rawData.length / samples);
				const filteredData = [];

				for(let i = 0; i < samples; i++) {
					const blockStart = blockSize * i;
					let sum = 0;

					for(let j = 0; j < blockSize; j++) {
						sum += Math.abs(rawData[blockStart + j]);
					}
					filteredData.push(sum / blockSize);
				}

				const multiplier = Math.max(...filteredData) ** -1;

				return filteredData.map(n => n * multiplier);
			}
		}
	};
</script>

<style lang="scss" scoped>
	audio {
		outline: none;

		&::-webkit-media-controls-panel {
			background-color: $color__white;
		}
	}

	.audio-background {
		position: relative;
		height: 80px;
		background-color: #222831;
		border-radius: $border_radius;

		.progress {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 0;
			background-color: rgba($color: $color__light_blue, $alpha: 0.25);
			transition: width .3s linear;
		}

		canvas {
			width: 100%;
			height: 100%;
		}

		button {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
			border-radius: 50%;
			background-color: $color__light_blue;
			color: $color__white;
			padding: 14px;
			border: 3px solid $color__background;
			transition: background-color .2s ease;
			outline: none;

			&:hover {
				background-color: $color__blue;
			}

			&.playing {
				background-color: $color__red;

				&:hover {
					background-color: darken($color__red, 10%);
				}
			}

			svg {
				display: block;
			}
		}
	}

	.meta {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	.audio-background,
	.meta {
		max-width: 600px;
	}
</style>