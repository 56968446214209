<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" :width="width" :height="height"><g :fill="_secondaryfill"><g class="nc-loop-circle-64-icon-f"><path d="M32 62a30 30 0 1 1 30-30 30.034 30.034 0 0 1-30 30zm0-55a25 25 0 1 0 25 25A25.028 25.028 0 0 0 32 7z" opacity=".4" :fill="fill" /><path d="M62 32h-5A25.028 25.028 0 0 0 32 7V2a30.034 30.034 0 0 1 30 30z" /></g></g></svg>
</template>

<style>.nc-loop-circle-64-icon-f{--animation-duration:0.5s;transform-origin:32px 32px;animation:nc-loop-circle-anim var(--animation-duration) infinite linear}@keyframes nc-loop-circle-anim{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}</style>

<script>
	export default {
		props: {
			width: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			height: {
				type: [
					Number,
					String
				],
				default: '100%'
			},
			strokewidth: {
				type: [
					Number,
					String
				],
				default: 1
			},
			fill: {
				type: String,
				default: 'currentColor'
			},
			secondaryfill: {
				type: String
			}
		},
		computed: {
			_secondaryfill() {
				return this.secondaryfill || this.fill;
			}
		}
	};
</script>