<template>
	<div :class="classes">
		<BaseButton v-if="transcript" type="secondary" class="toggle-transcript" @click="toggleTranscript">
			<IconChevron :width="14" :height="14" />
			<span>Show transcript</span>
		</BaseButton>
		<transition name="collapse">
			<div v-if="showTranscript" class="transcript" v-html="transcript"></div>
		</transition>
	</div>
</template>

<script>
	import {jsonToHtml} from '../inc/text';
	import BaseButton from './BaseButton';
	import IconChevron from './icons/IconChevron';

	export default {
		components: {
			BaseButton,
			IconChevron
		},
		props: {
			text: {
				type: [
					String,
					Object
				],
				required: true
			}
		},
		data() {
			return {
				showTranscript: false
			};
		},
		computed: {
			classes() {
				return `media-transcript${this.showTranscript ? ' transcript-open' : ''}`;
			},
			transcript() {
				if(typeof this.text === 'object') {
					return jsonToHtml(this.text);
				}
				else if(this.text && this.text.length) {
					const parts = this.text.split('\n\n');
					let transcript = '';

					for(const part of parts) {
						transcript += '<p>' + part + '</p>';
					}

					return transcript;
				}

				return '';
			}
		},
		methods: {
			toggleTranscript() {
				this.showTranscript = !this.showTranscript;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.transcript {
		padding: 19px;
		background-color: $color__white;
		border-radius: $border_radius;
	}

	.transcript-open {
		.toggle-transcript::v-deep svg {
			transform: rotate(180deg);
		}
	}

	.toggle-transcript::v-deep {
		svg {
			transition: transform ease 0.1s;
		}

		button {
			padding: 0;
		}
	}
</style>